import React, { useContext, useEffect, useState } from "react";
import { IoMdAdd, IoMdRemove, IoMdTrash } from "react-icons/io";
import { deepAppend, deepRemove } from "../../lib/helpers";
import { v4 as uuid } from "uuid";
import useMount from "../../hooks/useMount";
import { ReportContent } from "../../lib/db";

type ContentEditorProps = {
    saved?: ReportContent; 
    onChange: (update: any) => void;
}

const ContentEditorInterface = () => {
    const ContentEditorData = useContext(ContentEditorContext);

    const handleAddSegment = () => {
        ContentEditorData?.addSegment();
    }

    const handleTitleChange = (name: string) => (event: React.ChangeEvent<HTMLElement>) => {
        ContentEditorData?.updateName(name, event.target.innerText);
    }

    const handleListItemBlur = (segment: string, path: string[]) => (event: React.ChangeEvent<HTMLElement>) => {
        ContentEditorData?.updateSection(segment, path, event.currentTarget.innerText);
    }

    const handleListItemAdd = (segment: string, path: string[]) => () => {
        ContentEditorData?.addSection(segment, path, "");
    }

    const handleSegmentDelete = (segment: string) => () => {
        if (window.confirm("Confirm Delete")) {
            ContentEditorData?.removeSegment(segment);
        }
    }

    const handleListItemDelete = (segment: string, path: string[]) => () => {
        if (window.confirm("Confirm Delete")) {
            ContentEditorData?.removeSection(segment, path);
        }
    }

    const handleCompanyItemAdd = (path: string[]) => () => {
        ContentEditorData?.addCompany(path);
    }

    const handleCompanyItemDelete = (path: string[]) => () => {
        if (window.confirm("Confirm Delete")) {
            ContentEditorData?.removeCompany(path);
        }
    }

    const handleCompanyItemBlur = (path: string[]) => (event: React.ChangeEvent<HTMLElement>) => {
        ContentEditorData?.updateCompany(path, event.currentTarget.innerText);
    }

    const renderSegmentList = (segment: string, objects: Sections, path: string[]=[]) => {
        return Object.entries(objects).map((item, i) => (
            <div key={uuid()} className="pl-5">
                <div className="toc-list-item flex items-center gap-2">
                    <button onClick={handleListItemDelete(segment, [...path, item[0]])} className="w-3 h-3 flex items-center justify-center rounded-full text-zinc-400 hover:bg-red-500 hover:text-white"><IoMdRemove /></button>
                    {React.createElement("span", {
                        className: "outline-none",
                        contentEditable: true,
                        suppressContentEditableWarning: true,
                        children: item[0],
                        onBlur: handleListItemBlur(segment, [...path, item[0]])
                    })}
                    <button onClick={handleListItemAdd(segment, [...path, item[0]])} className="w-3 h-3 bg-zinc-200 flex items-center justify-center rounded-full hover:bg-blue-500 hover:text-white"><IoMdAdd /></button>
                </div>

                {renderSegmentList(segment, item[1], [...path, item[0]])}
            </div>
        ));
    }

    const renderCompanyList = (objects: Sections, path: string[]=[]) => {
        return Object.entries(objects).map(item => (
            <div key={uuid()} className="pl-5">
                <div className="toc-list-item flex items-center gap-2">
                    <button onClick={handleCompanyItemDelete([...path, item[0]])} className="w-3 h-3 flex items-center justify-center rounded-full text-zinc-400 hover:bg-red-500 hover:text-white"><IoMdRemove /></button>
                    {React.createElement("span", {
                        className: "outline-none",
                        contentEditable: true,
                        suppressContentEditableWarning: true,
                        children: item[0],
                        onBlur: handleCompanyItemBlur([...path, item[0]])
                    })}
                    <button onClick={handleCompanyItemAdd([...path, item[0]])} className="w-3 h-3 bg-zinc-200 flex items-center justify-center rounded-full hover:bg-blue-500 hover:text-white"><IoMdAdd /></button>
                </div>

                {renderCompanyList(item[1], [...path, item[0]])}                
            </div>
        ));
    }

    return (
        <div className="space-y-6">
            <h1 className="text-2xl font-bold">Table of Contents</h1>
            <div className="space-y-4">
                {ContentEditorData?.segments.map(segment => (
                    <div key={segment.by} className="relative space-y-2">
                        <div className="text-xl font-bold flex items-center gap-2">By <span className="outline-none focus:border-b" contentEditable suppressContentEditableWarning onBlur={handleTitleChange(segment.by)}>{segment.by}</span> <button onClick={handleSegmentDelete(segment.by)}><IoMdTrash /></button></div>
                        <div className="space-y-3">
                            {renderSegmentList(segment.by, segment.sections)}
                            <div className="pl-5">
                                <button onClick={handleListItemAdd(segment.by, [])} className="text-sm flex items-center gap-2 text-blue-500"><IoMdAdd /> Add Child</button>
                            </div>
                        </div>
                    </div>
                ))}

                {!ContentEditorData?.segments.some(segment => segment.by === "Name") && (
                    <button onClick={handleAddSegment} className="flex items-center justify-center gap-2 px-4 py-1 text-sm bg-zinc-100 rounded-full hover:bg-blue-500 hover:text-white"><IoMdAdd /> Add Segment</button>
                )}
            </div>
            
            <div className="space-y-2">
                <div className="text-xl font-bold flex items-center gap-2">Companies</div>
                <div>{renderCompanyList(ContentEditorData!.companies)}</div>
                <div className="pl-5">
                    <button onClick={handleCompanyItemAdd([])} className="text-sm flex items-center gap-2 text-blue-500"><IoMdAdd /> Add Company</button>
                </div>                
            </div>
        </div>
    )
}

type Sections = { [key: string]: Sections|{} };
type ContentEditorValue = {
    segments: Array<{by: string, sections: Sections}>,
    companies: Sections,
    addSegment: () => void;
    removeSegment: (name: string) => void;
    updateName: (prevName: string, newName: string) => void;
    addSection: (segment: string, path: string[], value: string) => void;
    updateSection: (segment: string, path: string[], value: string) => void;
    removeSection: (segment: string, path: string[]) => void;
    addCompany: (path: string[]) => void;
    removeCompany: (path: string[]) => void;
    updateCompany: (path: string[], value: string) => void;
};
const ContentEditorContext = React.createContext<ContentEditorValue|null>(null);

const ContentEditorProvider: React.FC<ContentEditorProps> = ({ saved, onChange }) => {
    const { mounted } = useMount();
    const [segments, setSegments] = useState<Array<{by: string, sections: Sections}>>(saved?.segments ?? []);
    const [companies, setCompanies] = useState<Sections>(saved?.companies ?? {});

    useEffect(() => {
       if (!mounted) return;
       onChange(({ segments, companies }));
    }, [mounted, segments, companies])

    const addSegment = () => {
        setSegments(prevState => [...prevState, {
            by: "Name",
            sections: {
                "": {}
            }
        }]);
    }

    const removeSegment = (name: string) => {
        setSegments(prevState => prevState.filter(segment => segment.by !== name));
    }

    const updateName = (prevName: string, newName: string) => {
        setSegments(prevState => prevState.map(segment => segment.by === prevName ? ({...segment, by: newName}) : segment));
    }

    const addSection = (segment: string, path: string[], value: string) => {
        setSegments(prevState => prevState.map(segmentItem => {
            if (segmentItem.by === segment) {
                let sections = deepAppend(segmentItem.sections, path, value);
                return { ...segmentItem, sections };
            }
            return segmentItem;
        }));
    }

    const updateSection = (segment: string, path: string[], value: string) => {
        setSegments(prevState => prevState.map(segmentItem => {
            if (segmentItem.by === segment) {
                let sections = deepRemove(segmentItem.sections, path);
                sections = deepAppend(sections, path.slice(0, -1), value);
                return { ...segmentItem, sections };
            }
            return segmentItem;
        }));
    }

    const removeSection = (segment: string, path: string[]) => {
        setSegments(prevState => {
            return prevState.map(segmentItem => {
                if (segmentItem.by === segment) {
                    const sections = deepRemove(segmentItem.sections, path); 
                    if (Object.keys(sections).length === 0) {
                        return {
                            ...segmentItem,
                            sections: { "": {} }
                        };
                    }
                    return {
                        ...segmentItem,
                        sections: deepRemove(segmentItem.sections, path)
                    };
                }
                return segmentItem;
            });
        });
    }

    const addCompany = (path: string[]) => {
        setCompanies(prevState => deepAppend(prevState, path, ""));
    }

    const removeCompany = (path: string[]) => {
        setCompanies(prevState => deepRemove(prevState, path));
    }

    const updateCompany = (path: string[], value: string) => {
        setCompanies(prevState => {
            let newState = deepRemove(prevState, path);
            newState = deepAppend(newState, path.slice(0, -1), value);
            return newState;
        });
    }

    const value: ContentEditorValue = {
        segments,
        companies,
        addSegment,
        addSection,
        updateName,
        updateSection,
        removeSegment,
        removeSection,
        addCompany,
        removeCompany,
        updateCompany
    };

    return (
        <ContentEditorContext.Provider value={value}>
            <ContentEditorInterface />
        </ContentEditorContext.Provider>
    )
}

const ContentEditor: React.FC<ContentEditorProps> = (props) => {
    return <ContentEditorProvider {...props} />;
}

export default ContentEditor;