import React, { useState } from "react";
import { DraftData, getReportDraft } from "../lib/db";

const useDraft = () => {
    const [draft, setDraft] = useState<DraftData|undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const fetchDraft = async (id: string) => {
        setLoading(true);
        const response = await getReportDraft(id);
        setDraft(response !== null ? response : undefined);
        setLoading(false);
    }

    return { draft, loading, fetchDraft };
}

export default useDraft;