import React, { useEffect } from "react";
import { Windmill } from "react-activity";
import { useParams } from "react-router-dom";
import Header from "../../components/core/Header";
import Editor from "../../components/editors/report";
import useDraft from "../../hooks/useDraft";
import useMarket from "../../hooks/useMarket";
import useMount from "../../hooks/useMount";

const ReportScreen = () => {
    const { mounted } = useMount();
    const { id } = useParams();
    const { market, fetchMarket } = useMarket();
    const { draft, loading, fetchDraft} = useDraft();

    useEffect(() => {
        if (!mounted || !id) return;
        fetchDraft(id);
        fetchMarket(id);
    }, [mounted, id])

    return (
        <div>
            <Header float={loading} small title={market?.market} />
            {loading && <div className="h-screen flex items-center justify-center"><Windmill /></div>}
            {mounted && !loading && <Editor saved={draft} />}
        </div>
    );
}

export default ReportScreen;