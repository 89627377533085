import React, { useEffect, useState } from "react";

export enum Theme {
    LIGHT = 0,
    DARK = 1,
};

type BrowserContextValue = {
    theme: Theme,
    offline: boolean
};
export const BrowserContext = React.createContext<BrowserContextValue|null>(null);

type BrowserProviderProps = {
    children: React.ReactNode
};
export const BrowserProvider: React.FC<BrowserProviderProps> = ({ children }) => {
    const [theme, setTheme] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches ? Theme.DARK : Theme.LIGHT);
    const [offline, setOffline] = useState(false);

    useEffect(() => {
        const handleNetwork = () => {
            setOffline(!navigator.onLine);
        }
        const handleThemeChange = (event: MediaQueryListEvent) => {
            setTheme(event.matches ? Theme.DARK : Theme.LIGHT);
        };

        window.addEventListener("online", handleNetwork);
        window.addEventListener("offline", handleNetwork);
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleThemeChange);

        return () => {
            window.removeEventListener("online", handleNetwork);
            window.removeEventListener("offline", handleNetwork);
            window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleThemeChange);
        }
    })

    const value: BrowserContextValue = {
        theme,
        offline
    };

    return (
        <BrowserContext.Provider value={value}>
            {children}
        </BrowserContext.Provider>
    )
}