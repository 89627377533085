import React, { useEffect, useState } from "react";
import { ApiError, User, UserCredentials } from "@supabase/supabase-js";
import { supabase } from "../../lib/db";

interface AuthProviderValue {
    user: User|null;
    authenticated: boolean;
    loading: boolean;
    error: ApiError|null;
    login: (credentials: UserCredentials) => Promise<boolean>;
    logout: () => Promise<boolean>;
    clearError: () => void;
}
export const AuthContext = React.createContext<AuthProviderValue|null>(null);

type AuthProviderProps = { children: React.ReactNode };
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User|null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ApiError|null>(null)

    useEffect(() => {
        if (!loading) {
            if (supabase.auth.user()) {
                setUser(supabase.auth.user());
            }            
        }
    }, [loading])

    useEffect(() => {
        const { data } = supabase.auth.onAuthStateChange(state => {
           setUser(supabase.auth.user());
        });

        return () => {
            data?.unsubscribe();
        }
    });

    const run = async (call: Promise<{ error: ApiError|null }>): Promise<boolean> => {
        setLoading(true);
        const { error } = await call;
        setLoading(false);
        if (error) {
            setError(error);
            return false
        };
        return true;
    }

    const login = async (credentials: UserCredentials): Promise<boolean> => {
        return await run(supabase.auth.signIn(credentials));
    }

    const logout = async (): Promise<boolean> => {
        return await run(supabase.auth.signOut());
    }

    const clearError = () => {
        setError(null);
    }

    const value: AuthProviderValue = {
        user,
        authenticated: user !== null,
        loading,
        error,
        login,
        logout,
        clearError
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}