import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IoCloudDoneOutline, IoBarChart, IoPieChart, IoCaretBack } from "react-icons/io5";
import { IoMdGrid, IoMdOpen, IoMdTrash } from "react-icons/io";
import { MdFirstPage } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import { DraftData, FeaturedComponent, FeaturedContent, GraphComponent, GraphType, setReportDraft, setReportFeaturedContent, TableComponent, TextComponent, TextType } from "../../lib/db";
import { Map } from "immutable";
import cn from "classnames";
import { parseTable } from "../../lib/data";
import useMount from "../../hooks/useMount";
import { Link, useParams } from "react-router-dom";
import { cursorPosition, cursorTo } from "../../lib/caret";
import ContentEditable from "../ui/ContentEditable";

const EditorInterface = () => {
    const { id } = useParams();
    const EditorData = useContext(EditorContext);
    const focusRef = useRef<HTMLElement|null>(null);
    const [inFocus, setInFocus] = useState<number|null>(null);
    const [objectPanel, setObjectPanel] = useState(false);
    const [objectIndex, setObjectIndex] = useState(0);
    const objectPanelStyle = { width: objectPanel ? 300 : "auto" };

    useEffect(() => {
        if (focusRef.current !== null) focusRef.current.focus();
    }, [inFocus, focusRef])

    const handleObjectPanel = () => setObjectPanel(prev => !prev);

    const handleObjectIndex = (index: number) => () => {
        if (!objectPanel) setObjectPanel(true);
        setObjectIndex(prev => prev === index ? 0 : index);
    };

    const handleTextType = (textType: TextType) => () => {
        if (inFocus !== null) {
            if (EditorData?.items.get(inFocus) && EditorData?.items.get(inFocus)?.component === FeaturedComponent.Text) {
                EditorData.updateTextType(inFocus, textType);
            }
        }
    }

    const handleFocus = (id: number) => () => {
        if (inFocus !== id) setInFocus(id);
    }

    const handleInput = (id: number) => (event: React.ChangeEvent<HTMLElement>) => {
        EditorData?.updateValue(id, event.target.innerText);
    }

    const handleKeyDown = (id: number) => (event: React.KeyboardEvent<HTMLElement>) => {
        
        if (event.key === "Enter") {
            event.preventDefault();
            const { position, endOffset } = cursorPosition(event.currentTarget);
            if (position === endOffset) {
                setInFocus(EditorData!.generateId(id));

                return EditorData!.insertItem(id);
            }
            let prefix = event.currentTarget.innerText.slice(0, position);
            let suffix = event.currentTarget.innerText.slice(position);

            EditorData?.updateValue(id, prefix);
            focusRef.current!.innerText = prefix;
            setInFocus(EditorData!.generateId(id));

            return EditorData!.insertItem(id, suffix);
        }

        if (event.key === "Backspace") {
            const { position, endOffset } = cursorPosition(event.currentTarget);
            if (position === 0 && id !== 0) {
                event.preventDefault();
                if (event.currentTarget.innerText !== "") {
                    const keyIndex = EditorData!.items.keySeq().sort((a, b) => a-b).toArray();
                    const previousId = keyIndex[keyIndex.indexOf(id) - 1];
                    const value = EditorData!.updateValue(previousId, event.currentTarget.innerText, true);
                    const target = (document.querySelector(`*[data-id="${previousId}"]`) as HTMLElement)
                    target.innerText = value;
                    cursorTo(target);
                }
                return EditorData?.removeItem(id);
            }
        }
    }
    const handleDelete = (id: number) => () => {
        return EditorData?.removeItem(id);
    }

    const handleDragStart = (id: number) => (event: React.DragEvent<HTMLElement>) => event.dataTransfer.setData("text/plain", JSON.stringify({ from: "items", source: id }));

    const handleObjectDragStart = (index: number) => (event: React.DragEvent<HTMLElement>) => event.dataTransfer.setData("text/plain", JSON.stringify({ from: "objects", source: index }));

    const handleDragOver = (event: React.DragEvent<HTMLElement>) => event.preventDefault();

    const handleDrop = (id: number) => (event: React.DragEvent<DataTransfer>) => {
        const { from, source } = JSON.parse(event.dataTransfer.getData("text/plain"));
        if (from === "objects") {
            EditorData?.insertObject(source, id);
        } else {
            EditorData?.rearrangeItems(source, id);
            (event.target as HTMLElement).innerText = (EditorData!.items.get(source) as TextComponent).value;
        }
        event.dataTransfer.clearData();
    }

    const handleDropAtEnd = (event: React.DragEvent<DataTransfer>) => {
        const { from, source } = JSON.parse(event.dataTransfer.getData("text/plain"));
        if (from === "objects") {
            EditorData?.insert(EditorData.objects[source]);
        } else {
            EditorData?.insert(EditorData.items.get(source)!, source);
        }
        event.dataTransfer.clearData();        
    }

    const handlePaste = (id: number) => (event: React.ClipboardEvent<HTMLElement>) => {
        event.preventDefault();
        let lines = event.clipboardData.getData("text").split("\n");
        if (lines.length > 0) {
            let value = lines[0];
            const { position } = cursorPosition(event.currentTarget as Node);
            if ( position !== 0 ) {
                let prefix = event.currentTarget.innerText.slice(0, position);
                let suffix = event.currentTarget.innerText.slice(position);
                if ('data' in window.getSelection()!.focusNode! as any) {
                    prefix = prefix.replace((window.getSelection()!.focusNode! as any).data, "");
                }
                value = prefix + value;
                if (lines.length > 1) {
                    lines[lines.length - 1] += suffix;
                } else {
                    value += suffix;
                }
            } else {
                if (lines.length > 1) {
                    lines[lines.length - 1] += event.currentTarget.innerText;
                } else {
                    value += event.currentTarget.innerText;
                }
            }
            EditorData?.updateValue(id, value);
            event.currentTarget.innerText = value;
            lines.slice(1)
                .filter(value => value.trim() !== "")
                .reduce((prevId, value) => EditorData?.insertItem(prevId, value)!, id);
        }
    }

    const handleObjectPaste = (event: React.ClipboardEvent<HTMLElement>) => {
        event.preventDefault();
        event.currentTarget.blur();
        const rawText = event.clipboardData.getData("text");
        const table = parseTable(rawText);
        const title = table.title ?? window.prompt("Table Title") ?? "";

        EditorData?.addObject({
            component: FeaturedComponent.Table,
            title,
            name: table.header[0],
            ...table,
            baseYearIndex: -1,
            compareYearIndex: -1
        });
    }

    const handleObjectConvert = (convertTo: GraphType|"Table", index: number, object: TableComponent|GraphComponent) => () => {
        if (convertTo === "Table") {
            const { type, otherVariant, variantRowIndex, ...otherProps } = object as GraphComponent;
            EditorData?.updateObject(index, {
                ...otherProps,
                component: FeaturedComponent.Table
            });
            return;
        }

        EditorData?.updateObject(index, { 
            ...object, 
            component: FeaturedComponent.Graph, 
            type: convertTo,
            otherVariant: false,
            variantRowIndex: -1
        });
    }

    const handleObjectDelete = (index: number) => () => {
        EditorData?.removeObject(index);
    }

    const handlePublish = async () => {
        await EditorData?.publishChanges();
    }

    const renderReactComponent = (key: number, props: FeaturedContent) => {
        if (props.component === FeaturedComponent.Text) {
            return <div key={key.toString()} className="wrapper relative">
                <ContentEditable
                    ref={key === inFocus ? focusRef : null}
                    draggable
                    contentEditable
                    suppressContentEditableWarning
                    data-id={key}
                    value={props.value}
                    tagname={props.name}
                    className="outline-none"
                    onDragStart={handleDragStart(key)}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop(key)}
                    onKeyDown={handleKeyDown(key)}
                    onInput={handleInput(key)}
                    onPaste={handlePaste(key)}
                    onFocus={handleFocus(key)}
                />
                {/* {React.createElement(props.name, {
                    "data-id": key,
                    ref: key === inFocus ? focusRef : null,
                    children: props.value,
                    className: "outline-none",
                    contentEditable: true,
                    draggable: true,
                    suppressContentEditableWarning: true,
                    onDragStart: handleDragStart(key),
                    onDragOver: handleDragOver, 
                    onDrop: handleDrop(key),
                    onKeyDown: handleKeyDown(key),
                    onBlur: handleBlur(key),
                    onPaste: handlePaste(key),
                    onFocus: handleFocus(key)
                })} */}

                {key !== 1 && (
                    <button onClick={handleDelete(key)} className="absolute top-0 -right-8 text-lg">
                        <IoMdTrash />
                    </button>
                )}
            </div>;
        }

        if (props.component === FeaturedComponent.Table) {
            return (
                <div key={key.toString()} className="wrapper relative">
                    <div 
                        data-id={key}
                        draggable 
                        onDragStart={handleDragStart(key)} 
                        onDragOver={handleDragOver}
                        onDrop={handleDrop(key) as any}
                        className="border rounded-lg divide-y">
                            <div className="flex items-center gap-1 text-blue-500 p-2">
                                <IoMdGrid /> Table
                            </div>
                            <div className="p-2">{props.title}</div>
                    </div>
                    <button onClick={handleDelete(key)} className="absolute top-0 -right-8 text-lg">
                        <IoMdTrash />
                    </button>
                </div>
            );
        }

        if (props.component === FeaturedComponent.Graph) {
            return (
                <div key={key.toString()} className="wrapper relative">
                    <div 
                        data-id={key}
                        draggable 
                        onDragStart={handleDragStart(key)} 
                        onDragOver={handleDragOver}
                        onDrop={handleDrop(key) as any}
                        className="border rounded-lg divide-y">
                            <div className="flex items-center gap-1 text-blue-500 p-2 capitalize">
                                {props.type === GraphType.Bar ? <IoBarChart /> : <IoPieChart /> } {props.type} Graph
                            </div>
                            <div className="p-2">{props.title}</div>
                    </div>
                    <button onClick={handleDelete(key)} className="absolute top-0 -right-8 text-lg">
                        <IoMdTrash />
                    </button>
                </div>
            );
        }

        return React.Fragment({});
    } 

    const renderObject = (props: TableComponent|GraphComponent, index: number) => {
        if (props.component === FeaturedComponent.Table) {
            return (
                <div draggable onDragStart={handleObjectDragStart(index)} key={index.toString()} className="relative p-2 border rounded-lg space-y-1">
                    <div className="flex items-center text-sm gap-1 text-blue-500">
                        <IoMdGrid /> Table
                    </div>
                    <div className="text-sm">{props.title}</div>

                    <div className="pt-2 flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <button onClick={handleObjectConvert(GraphType.Bar, index, props)} className="py-1 px-2 flex items-center text-xs gap-1 bg-zinc-100 rounded"><IoBarChart /> Bar Graph</button>
                            <button onClick={handleObjectConvert(GraphType.Pie, index, props)} className="py-1 px-2 flex items-center text-xs gap-1 bg-zinc-100 rounded"><IoPieChart /> Pie Graph</button>
                        </div>

                        <button onClick={handleObjectDelete(index)}><IoMdTrash /></button>
                    </div>
                </div>
            )
        }

        if (props.component === FeaturedComponent.Graph) {
            return (
                <div draggable onDragStart={handleObjectDragStart(index)} onDragOver={handleDragOver} key={index.toString()} className="relative p-2 border rounded-lg space-y-1">
                    <div className="flex items-center text-sm gap-1 text-blue-500 capitalize">
                        {props.type === GraphType.Bar ? <IoBarChart /> : <IoPieChart />} {props.type} Graph
                    </div>
                    <div className="text-sm">{props.title}</div>

                    <div className="pt-2 flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <button onClick={handleObjectConvert("Table", index, props)} className="py-1 px-2 flex items-center text-xs gap-1 bg-zinc-100 rounded"><IoMdGrid /> Table</button>
                            {props.type === GraphType.Bar 
                                ? <button onClick={handleObjectConvert(GraphType.Pie, index, props)} className="py-1 px-2 flex items-center text-xs gap-1 bg-zinc-100 rounded"><IoPieChart /> Pie Graph</button>
                                :<button onClick={handleObjectConvert(GraphType.Bar, index, props)} className="py-1 px-2 flex items-center text-xs gap-1 bg-zinc-100 rounded"><IoBarChart /> Bar Graph</button>
                            }
                        </div>

                        <button onClick={handleObjectDelete(index)}><IoMdTrash /></button>
                    </div>
                </div>
            )
        }        

        return <></>;
    }

    return (
        <div id="Editor">
            <header className="bg-white sticky top-14 divide-y divide-zinc-100 z-50">
                <div className="h-12 flex items-center justify-between p-4 border-b">
                    <div className="flex-1 flex items-center gap-4">
                        <Link to={`/market/${id}`} className="font-bold text-sm flex items-center gap-1 hover:text-blue-500"><IoCaretBack /> Back</Link>
                        <Link className="text-sm gap-1 flex items-center" target={"_blank"} to={`/market/${id}/meta`}>
                            Meta <IoMdOpen className="text-xs" />
                        </Link>
                        <Link className="text-sm gap-1 flex items-center" target={"_blank"} to={`/market/${id}/contents`}>
                            TOC <IoMdOpen className="text-xs" />
                        </Link>
                        <a className="text-sm gap-1 flex items-center" target={"_blank"} href={`https://consainsights.com/reports/${id}-market?preview=true`}>
                            Live Page <IoMdOpen className="text-xs" />
                        </a>
                    </div>
                    <div className="flex-1 flex items-center justify-center gap-5 font-bold">
                        <button className={cn("w-8 h-8 flex justify-center items-center rounded transition-transform duration-500 hover:bg-zinc-100", { "scale-95 text-zinc-400 cursor-not-allowed": inFocus === null })} onClick={handleTextType(TextType.H1)}>H1</button>
                        <button className={cn("w-8 h-8 flex justify-center items-center rounded transition-transform duration-500 hover:bg-zinc-100", { "scale-95 text-zinc-400 cursor-not-allowed": inFocus === null })} onClick={handleTextType(TextType.H2)}>H2</button>
                        <button className={cn("w-8 h-8 flex justify-center items-center rounded transition-transform duration-500 hover:bg-zinc-100", { "scale-95 text-zinc-400 cursor-not-allowed": inFocus === null })} onClick={handleTextType(TextType.H3)}>H3</button>
                        <button className={cn("w-8 h-8 flex justify-center items-center rounded transition-transform duration-500 hover:bg-zinc-100", { "scale-95 text-zinc-400 cursor-not-allowed": inFocus === null })} onClick={handleTextType(TextType.H4)}>H4</button>
                        <button className={cn("w-8 h-8 flex justify-center items-center rounded transition-transform duration-500 hover:bg-zinc-100", { "scale-95 text-zinc-400 cursor-not-allowed": inFocus === null })} onClick={handleTextType(TextType.P)}>P</button>
                        {/* <button className="w-8 h-8 flex justify-center items-center rounded hover:bg-zinc-100" onClick={handleTextType(TextType.DIV)}><IoIosList /></button> */}
                    </div>
                    <div className="flex-1 flex justify-end items-center gap-6">
                        <div className={cn("flex items-center gap-1 text-sm", { "text-green-600": !EditorData?.saving, "text-zinc-600": EditorData?.saving })}>
                            {EditorData?.saving ? <BiRefresh className="animate-spin" /> : <IoCloudDoneOutline className="text-base" />}
                            <span>{EditorData?.saving ? "Saving..." : "Saved"}</span>
                        </div>
                        <button disabled={EditorData?.publishing} onClick={handlePublish} className="bg-black px-4 py-1 rounded-md font-medium text-white text-sm disabled:bg-zinc-400">{EditorData?.publishing ? <BiRefresh className="animate-spin" /> : "Publish"}</button>
                    </div>
                </div>
            </header>

            <div id="Editor-Content" className="mx-auto my-10 px-8 max-w-screen-md space-y-4">
                {Array.from(EditorData?.items.entries() ?? []).sort(([a, _], [b, __]) => a-b).map(([id, item]) => renderReactComponent(id, item))}
                <div contentEditable suppressContentEditableWarning onFocus={event => event.currentTarget.blur()} onDrop={handleDropAtEnd as any} className="h-10 cursor-default"></div>
            </div>

            <div style={{top: 105, ...objectPanelStyle}} className="fixed bottom-0 right-0 py-4 px-2 border-l border-zinc-100 flex flex-col justify-between items-center gap-3 bg-white z-50">
                <div className={cn("flex gap-4 items-center", { "flex-col": !objectPanel })}>
                    <button onClick={handleObjectIndex(1)} name="Bar Graph" className={cn("w-10 h-10 flex justify-center items-center text-lg rounded-full hover:bg-zinc-100", { "bg-blue-500 text-white": objectPanel && objectIndex === 1 })}><IoBarChart /></button>
                    <button onClick={handleObjectIndex(2)} name="Pie Graph" className={cn("w-10 h-10 flex justify-center items-center text-lg rounded-full hover:bg-zinc-100", { "bg-blue-500 text-white": objectPanel && objectIndex === 2 })}><IoPieChart /></button>
                    <button onClick={handleObjectIndex(3)} name="Table" className={cn("w-10 h-10 flex justify-center items-center text-lg rounded-full hover:bg-zinc-100", { "bg-blue-500 text-white": objectPanel && objectIndex === 3 })}><IoMdGrid /></button>
                </div>
                {objectPanel && (
                    <div className="w-full">
                        <input 
                            className="text-center outline-none border border-zinc-200 border-dashed w-full rounded-lg py-2" 
                            onKeyDown={event => ((event.metaKey || event.ctrlKey) && event.key === "v") || event.preventDefault()}
                            onPaste={handleObjectPaste} 
                            placeholder="Paste Here" />
                    </div>
                )}
                <div className={cn("flex-1 w-full overflow-y-auto space-y-2", { "hidden": !objectPanel })}>
                    {objectIndex === 0 && EditorData?.objects.map(renderObject)}
                    {objectIndex === 1 && EditorData?.objects.filter(object => object.component === FeaturedComponent.Graph && object.type === GraphType.Bar).map(renderObject)}
                    {objectIndex === 2 && EditorData?.objects.filter(object => object.component === FeaturedComponent.Graph && object.type === GraphType.Pie).map(renderObject)}
                    {objectIndex === 3 && EditorData?.objects.filter(object => object.component === FeaturedComponent.Table).map(renderObject)}
                </div>
                <button onClick={handleObjectPanel} name="Expand" className={cn("flex justify-center items-center text-lg rounded-full bg-zinc-100 gap-1 cursor-pointer", { "w-10 h-10": !objectPanel, "p-2": objectPanel })}>
                    <MdFirstPage className={cn({ "rotate-180": objectPanel })} />
                    {objectPanel ? <span className="text-sm">collapse</span> : ""}
                </button>
            </div>
        </div>
    )
}

type EditorContentValue = {
    items: Map<number, FeaturedContent>;
    objects: Array<TableComponent|GraphComponent>;
    saving: boolean;
    publishing: boolean;
    generateId: (prevId: number) => number;
    updateValue: (id: number, value: string, join?:boolean) => string;
    updateTextType: (id: number, name: TextType) => void;
    insert: (value: TextComponent|GraphComponent|TableComponent, source?: number) => number;
    insertItem: (nextTo: number, value?: string) => number;
    rearrangeItems: (source: number, target: number) => void;
    insertObject: (objectIndex: number, target: number) => void;
    removeItem: (id: number) => void;
    addObject: (object: TableComponent|GraphComponent) => void;
    removeObject: (index: number) => void;
    updateObject: (index: number, object: TableComponent|GraphComponent) => void;
    publishChanges: () => Promise<boolean>;
};

const EditorContext = React.createContext<EditorContentValue|null>(null);

type EditorData = {
    items: Map<number, FeaturedContent>;
    objects: Array<TableComponent|GraphComponent>;
};
const EditorProvider: React.FC<{ saved: EditorData }> = ({ saved }) => {
    const { mounted } = useMount();
    const { id } = useParams();
    const [items, setItems] = useState<Map<number, FeaturedContent>>(saved.items);
    const [objects, setObjects] = useState<Array<TableComponent|GraphComponent>>(saved.objects);
    const [saving, setSaving] = useState(false);
    const [publishing, setPublishing] = useState(false);

    useEffect(() => {
        if (!mounted) return;
        if (items.count() === 0) {
            setItems(prevState => prevState.set(1, {
                component: FeaturedComponent.Text,
                name: TextType.P,
                value: "",
                isPoint: false
            }));
        } else {
            saveChanges();
        }
    }, [mounted, items])

    const saveChanges = async () => {
        const contents: FeaturedContent[] = Array.from(items.entries()).sort(([a, _], [b, __]) => a-b).map(([_, value]) => value);
        setSaving(true);
        await setReportDraft(id!, { items: contents, objects });
        setSaving(false);
    }

    const generateId = (prevId: number) => {
        if (String(prevId).indexOf(".") !== -1 || items.has(prevId + 1)) {
            let increment = 0.1;
            while (items.has(prevId + increment)) { increment /= 10; }
            return prevId + increment;
        }

        return prevId + 1;
    }

    const updateValue = (id: number, value: string, join=false): string => {
        const newValue = join ? (items!.get(id) as TextComponent).value + value : value;
        setItems(prevState => prevState.update(id, entry => ({ ...entry, value: newValue }) as TextComponent));
        return newValue;
    }

    const updateTextType = (id: number, name: TextType) => {
        setItems(prevState => prevState.update(id, entry => ({ ...entry, name }) as TextComponent))
    }

    const insert = (value: TextComponent|GraphComponent|TableComponent, source?: number) => {
        let id = generateId(items.keySeq().last());
        if (typeof source !== "undefined") {
            setItems(prevState => prevState.set(id, value).delete(source));
        } else {
            setItems(prevState => prevState.set(id, value));
        }
        return id;
    }

    const insertItem = (nextTo: number, value="") => {
        let id = generateId(nextTo);

        setItems(prevState => prevState.set(id, {
            component: FeaturedComponent.Text, 
            name: TextType.P,
            value: value,
            isPoint: false
        }));

        return id;
    }

    const insertObject = (objectIndex: number, target: number) => {
        let targetData = items.get(target)!;
        let objectData = objects[objectIndex];
        let newId = generateId(target);

        setItems(prevState => prevState.set(newId, targetData).update(target, _ => objectData));
    }

    const rearrangeItems = (source: number, target: number) => {
        let sourceData = items.get(source)!;
        let targetData = items.get(target)!;
        let newId = generateId(target);

        setItems((prevState) => prevState.set(newId, targetData).update(target, _ => sourceData).delete(source));
    }

    const removeItem = (id: number) => {
        setItems(prevState => prevState.delete(id));
    }

    const addObject = (object: TableComponent|GraphComponent) => {
        setObjects(prev => [...prev, object]);
    }

    const removeObject = (index: number) => {
        setObjects(prev => prev.filter((_, i) => index !== i));
    }

    const updateObject = (index: number, object: TableComponent|GraphComponent) => {
        setObjects(prev => prev.map((_, i) => index === i ? object : _));
    }

    const publishChanges = async () => {
        setPublishing(true);
        const contents = Array.from(items.entries()).sort(([a, _], [b, __]) => a-b)
            .map(([_, value]) => {
                if (value.component === FeaturedComponent.Graph) {
                    if (value.type === GraphType.Pie) {
                        return {...value, baseYearIndex: 5, rows: value.rows.filter(row => row.length === value.header.length)};
                    }
                    return {...value, rows: value.rows.filter(row => row.length === value.header.length)};
                }

                if (value.component === FeaturedComponent.Table) {
                    return {...value, rows: value.rows.filter(row => row.length === value.header.length)}
                }

                return value
            });
        const success = await setReportFeaturedContent(id!, contents);
        setPublishing(false);
        if (success) {
            alert("Published successfully!");
            return true;
        }
        alert("Failed to publish.");
        return false;
    }

    const value: EditorContentValue = {
        items,
        objects,
        saving,
        publishing,
        generateId,
        updateValue,
        updateTextType,
        insert,
        insertItem,
        insertObject,
        rearrangeItems,
        removeItem,
        addObject,
        removeObject,
        updateObject,
        publishChanges
    };

    return (
        <EditorContext.Provider value={value}>
            <EditorInterface />
        </EditorContext.Provider>
    )
}

const Editor: React.FC<{ saved?: DraftData }> = ({ saved }) => {
    const draft: EditorData = useMemo(() => {
        return {
            items: Map(
                typeof saved?.items !== "undefined" 
                    ? saved.items.map((item, i) => [i+1, item]) 
                    : []
            ),
            objects: typeof saved?.objects !== "undefined" ? saved.objects : []
        }
    }, [saved])

    return (
        <EditorProvider saved={draft} />
    )
}

export default Editor;