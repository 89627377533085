type GenericObject = { [key: string]: any };

export function deepRemove(object: GenericObject, path: string[]): GenericObject {
    if (path.length > 0) {
        if (path.slice(1).length === 0) {
            const { [path[0]]: _, ...other } = object;
            return other;
        }
        
        return {
            ...object,
            [path[0]]: deepRemove(object[path[0]], path.slice(1))
        };
    }
    return object;
}

export function deepAppend(object: GenericObject, path: string[], value: string): GenericObject {
    if (path.length === 0) return { ...object, [value]: {} };
    if (path.length === 1) return {...object, [path[0]]: { ...object[path[0]], [value]: {} } };
    return { ...object, [path[0]]: deepAppend(object[path[0]], path.slice(1), value) };
}