import React, { useEffect, useState } from "react";
import { IoCaretBack } from "react-icons/io5";
import { BiRefresh } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/core/Header";
import MetaEditor from "../../components/editors/meta";
import useMarket from "../../hooks/useMarket";
import useMount from "../../hooks/useMount";
import { deleteReportMeta, MetaData, setReportMeta } from "../../lib/db";
import { Windmill } from "react-activity";
import { IoMdOpen } from "react-icons/io";

const MetaScreen = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const { mounted } = useMount();
    const { market, loading, fetchMarket } = useMarket(true);
    const [meta, setMeta] = useState<MetaData|null>(null);
    const [saving, setSaving] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [canReset, setCanReset] = useState(false);

    useEffect(() => {
        if (!loading && market) {
            setCanReset(Object.keys(market?.meta ?? {}).length > 0);
        }
    }, [loading, market])

    useEffect(() => {
        if (mounted && id) {
            fetchMarket(id);
        }
    }, [mounted, id])

    const handleChange = (update: any) => {
        setMeta(update);
    }

    const prepareMeta = (data: MetaData): MetaData => {
        let result = {};
        if (data.meta) {
            let meta = Object.fromEntries(Object.entries(data.meta).filter(([key, val]) => val !== ""));
            if (Object.keys(meta).length > 0) {
                result = {...result, meta};
            }
        }   

        if (data.body) {
            let body = Object.fromEntries(Object.entries(data.body).filter(([key, val]) => val !== ""));
            if (Object.keys(body).length > 0) {
                result = {...result, body};
            }
        }

        return result;
    }

    const saveChanges = async () => {
        if (!id || !meta) return;
        setSaving(true);
        let payload = prepareMeta(meta);
        if (Object.keys(payload).length > 0) {
            const success = await setReportMeta(id, payload);
            if (!success) alert("Failed to save!");
            else setCanReset(true);
        }
        setSaving(false);
    }

    const resetChanges = async () => {
        if (window.confirm("Clear all changes?")) {
            setResetting(true);
            const success = await deleteReportMeta(id!);
            if (!success) {
                alert("Failed to reset");
            } else {
                navigate(`/market/${id}`, { replace: true });
            }
            setResetting(false);
        }
    }

    return (
        <div>
            <Header small />
            <div className="bg-white sticky top-14 px-4 border-b border-zinc-100 h-12 z-50 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <Link to={`/market/${id}`} className="font-bold flex items-center gap-1 hover:text-blue-500"><IoCaretBack /> {market?.market} Market</Link>
                    <a className="text-sm gap-1 flex items-center" target={"_blank"} href={`https://consainsights.com/reports/${id}-market?preview=true`}>
                        Live Page <IoMdOpen className="text-xs" />
                    </a>
                </div>

                <div className="flex items-center gap-4">
                    <button disabled={!canReset || resetting} onClick={resetChanges} className="bg-red-500 px-4 py-1 rounded-md font-medium text-white text-sm disabled:bg-zinc-400">{resetting ? <BiRefresh className="animate-spin" /> : "Reset"}</button>
                    <button disabled={saving} onClick={saveChanges} className="bg-black px-4 py-1 rounded-md font-medium text-white text-sm disabled:bg-zinc-400">{saving ? <BiRefresh className="animate-spin" /> : "Save"}</button>
                </div>
            </div>
            <div className="mx-auto my-10 max-w-screen-lg">
                {loading && <div style={{ height: "calc(100vh - 200px)" }} className="flex items-center justify-center"><Windmill /></div>}
                {!loading && <MetaEditor saved={market?.meta} onChange={handleChange} />}
            </div>
        </div>
    );
}

export default MetaScreen;