import React, { useMemo, useState } from "react";
import { Windmill } from "react-activity";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import RoundButton from "../../components/ui/RoundButton";
import { supabase } from "../../lib/db";

export default function PasswordReset() {
    const navigate = useNavigate();
    const [password, setPassword] = useState<string|null>(null);
    const [confirmPassword, setConfirmPassword] = useState<string|null>(null);
    const [error, setError] = useState<string|null>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => document.getElementById("submit")?.click();

    const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!password) {
            setError("Invalid password");
            return;
        }

        if (password !== confirmPassword) {
            setError("Password doesn't match");
            return;
        }

        setLoading(true);
        const { error } = await supabase.auth.update({ password });

        if (error) {
            setError(error.message);
            return;
        }
        setLoading(false);
        window.location.reload();
    }

    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div className="w-96 p-2 border border-zinc-100 rounded-2xl shadow-2xl">
                <header className="bg-zinc-50 py-1 px-4 rounded-xl border border-zinc-100">
                    <div className="flex items-center font-bold"><span className="text-2xl pr-2">☗</span> Datahouse</div>
                </header>

                <div className="px-4 pt-6 pb-3">
                    <form className="w-full flex flex-col gap-3" onSubmit={handleReset}>
                        <div className="flex flex-col gap-1">
                            <label htmlFor="email">New Password</label>
                            <input 
                                onChange={event => setPassword(event.target.value)}
                                required 
                                type="password" 
                                id="password" 
                                name="password" 
                                placeholder="**********" 
                                className="w-full text-lg outline-none font-bold placeholder:font-normal placeholder:text-zinc-300" />
                        </div>
                        <div className="flex flex-col gap-1">
                            <label htmlFor="password">Confirm Password</label>
                            <input 
                                onChange={event => setConfirmPassword(event.target.value)}
                                required 
                                type="password" 
                                id="confirm-password" 
                                placeholder="**********" 
                                className="w-full text-lg outline-none font-bold placeholder:font-normal placeholder:text-zinc-300" />
                        </div>
                        {error && <div className="text-sm text-red-500">{error}</div>}
                        <input id="submit" type="submit" hidden />
                        <div className="space-x-6 flex items-center">
                            <RoundButton name="Confirm" onClick={handleSubmit} disabled={loading}>
                                {loading ? <Windmill /> : <IoIosArrowRoundForward className="text-3xl" />}
                            </RoundButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}