import React, { useContext, useState } from "react";
import { AuthContext } from "../../components/contexts/Auth";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoRefreshSharp } from "react-icons/io5";
import { Windmill } from "react-activity";
import RoundButton from "../../components/ui/RoundButton";

export default function AuthScreen() {
    const Auth = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await Auth?.login({ email, password });
    }

    const handleFormReset = () => {
        setEmail("");
        setPassword("");
        Auth?.clearError();
    }

    const handleSubmit = () => document.getElementById("submit")?.click();

    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div className="w-96 p-2 border border-zinc-100 rounded-2xl shadow-2xl">
                <header className="bg-zinc-50 py-1 px-4 rounded-xl border border-zinc-100">
                    <div className="flex items-center font-bold"><span className="text-2xl pr-2">☗</span> Datahouse</div>
                </header>

                <div className="px-4 pt-6 pb-3">
                    <form className="w-full flex flex-col gap-3" onSubmit={handleLogin} onReset={handleFormReset}>
                        <div className="flex flex-col gap-1">
                            <label htmlFor="email">Email</label>
                            <input 
                                onChange={event => setEmail(event.target.value)}
                                required 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="alex.jhon@example.com" 
                                className="w-full text-lg outline-none font-bold placeholder:font-normal placeholder:text-zinc-300" />
                        </div>
                        <div className="flex flex-col gap-1">
                            <label htmlFor="password">Password</label>
                            <input 
                                onChange={event => setPassword(event.target.value)}
                                required 
                                type="password" 
                                id="password" 
                                placeholder="**********" 
                                className="w-full text-lg outline-none font-bold placeholder:font-normal placeholder:text-zinc-300" />
                        </div>
                        {Auth?.error && <div className="text-sm text-red-500">{Auth.error.message}</div>}
                        <input id="submit" type="submit" hidden />
                        <div className="space-x-6 flex items-center">
                            <RoundButton name="Login" onClick={handleSubmit} disabled={Auth?.loading}>
                                {Auth?.loading ? <Windmill /> : <IoIosArrowRoundForward className="text-3xl" />}
                            </RoundButton>
                            {!Auth?.loading && (
                                <RoundButton name="Reset" type="reset" className="text-xl">
                                    <IoRefreshSharp className="text-2xl" />
                                </RoundButton>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}