import { useEffect, useRef, useState } from "react";

export default function useMount() {
    const mountRef = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (mountRef.current) return;

        setMounted(true);
        mountRef.current = true;
    }, [mountRef])

    return { mounted };
}