export type Table = {
    title?: string;
    header: string[];
    rows: Array<string[]>;
}
export function parseTable(str: string): Table {
    let rows = str.split("\n");
    if (rows.length > 1) {
        let firstRow = rows[0].split("	");
        let hasTitle = firstRow[0] !== "" && firstRow.slice(1).every(val => val === "");

        if (hasTitle) {
            return {
                title: firstRow[0],
                header: rows[1].split("	"),
                rows: rows.slice(2).map(row => row.split("	"))
            };
        }
        
        return {
            header: rows[0].split("	"),
            rows: rows.slice(1).map(row => row.split("	"))
        };
    }

    return {
        header: [],
        rows: rows.map(row => row.split("	"))
    };
}