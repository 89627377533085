
import { BrowserProvider } from "./components/contexts/Browser";
import { AuthProvider } from "./components/contexts/Auth";
import React from 'react';
import Navigation from "./routes/Navigation";

function App() {
  return (
    <BrowserProvider>
      <AuthProvider>
        <Navigation />
      </AuthProvider>
    </BrowserProvider>
  );
}

export default App;
