import React, { useContext, useEffect, useState } from "react";
import { Windmill } from "react-activity";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../components/contexts/Auth";
import useMount from "../hooks/useMount";
import Entry from "./Entry";
import ContentScreen from "./Market/ContentScreen";
import MarketScreen from "./Market/MarketScreen";
import MetaScreen from "./Market/MetaScreen";
import ReportScreen from "./Market/ReportScreen";

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { mounted } = useMount();
    const Auth = useContext(AuthContext);

    if (!mounted || Auth?.loading) {
        return <div className="h-screen flex items-center justify-center"><Windmill /></div>;
    }

    return Auth?.authenticated ? <>{children}</> : <Navigate to="/" replace />;
} 

export default function Navigation() {
    return (
        <Router>
            <Routes>
                <Route path="/market">
                    <Route path=":id" element={<RequireAuth><MarketScreen /></RequireAuth>} />
                    <Route path=":id/meta" element={<RequireAuth><MetaScreen /></RequireAuth>} />
                    <Route path=":id/contents" element={<RequireAuth><ContentScreen /></RequireAuth>} />
                    <Route path=":id/report" element={<RequireAuth><ReportScreen /></RequireAuth>} />
                </Route>
                <Route path="/" element={<Entry />} />
            </Routes>
        </Router>
    )
}