import { useEffect } from "react";
import { Windmill } from "react-activity";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/core/Header";
import useMarket from "../../hooks/useMarket";
import useMount from "../../hooks/useMount";
import { IoIosCode, IoIosList, IoIosResize } from "react-icons/io";
import RoundButton from "../../components/ui/RoundButton";

export default function MarketScreen() {
    const { mounted } = useMount();
    const { id } = useParams();
    const { market, loading, fetchMarket } = useMarket();

    useEffect(() => {
        if (mounted && id) {
            fetchMarket(id);
        }
    }, [mounted, id])

    return (
        <div>
            <Header float />

            <div className="h-screen flex flex-col items-center justify-center gap-5">                
                {market && (
                    <>
                        <h1 className="font-bold text-3xl">{market.market} Market</h1>
                        <div className="flex items-center justify-center gap-6">
                            <Link to={`/market/${id}/meta`}>
                                <RoundButton name="Meta"><IoIosCode className="text-xl" /></RoundButton>
                            </Link>
                            <Link to={`/market/${id}/contents`}>
                                <RoundButton name="Contents"><IoIosList className="text-xl" /></RoundButton>
                            </Link>
                            <Link to={`/market/${id}/report`}>
                                <RoundButton name="Report"><IoIosResize className="text-xl" /></RoundButton>                            
                            </Link>
                        </div>
                    </>
                )}
                {loading && <Windmill />}
            </div>
        </div>
    )
}