import React, { useState } from "react";
import { IoIosSearch, IoIosNotifications, IoIosDocument } from "react-icons/io";
import useSearch from "../../hooks/useSearch";
import ResultItem from "../../components/core/ResultItem";
import Header from "../../components/core/Header";

export default function HomeScreen() {
    const [query, setQuery] = useState("");
    const { results, loading, search } = useSearch();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!loading && query !== "") return await search(query);
    };

    const RenderLoading = () => (
        <>
            {Array(10).fill(null).map((_, i) => (
                <div key={i.toString()} className="p-3 space-y-2">
                    <div className="w-96 h-4 bg-zinc-200 animate-pulse rounded-sm"></div>
                    <div className="flex items-center gap-4">
                        <div className="w-20 h-3 bg-zinc-100"></div>
                        <div className="w-20 h-3 bg-zinc-100"></div>
                        <div className="w-20 h-3 bg-zinc-100"></div>
                    </div>
                </div>
            ))} 
        </>
    )

    return (
        <div>
            <Header />
            <div>
                <div className="mx-auto my-10 max-w-screen-lg grid grid-cols-3 gap-14">
                    <div className="col-span-2 space-y-4">
                        <form onSubmit={handleSubmit} className="mt-1.5 border border-zinc-300 px-3 py-1 rounded-md flex items-center gap-3">
                            <IoIosSearch />
                            <input 
                                id="search" 
                                name="search" 
                                className="flex-1 outline-none" 
                                type="search" 
                                placeholder="Search Market"
                                onChange={event => setQuery(event.target.value)} />
                        </form>

                        {results.length > 0 && (
                            <div className="space-y-2">
                                <h2 className="font-bold">Search Results</h2>

                                <div className="border border-zinc-300 rounded-lg overflow-hidden divide-y">
                                    {loading ? <RenderLoading /> : results.map(result => (
                                        <ResultItem key={result.id} {...result} />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-span-1 space-y-4">
                        <div className="space-y-1">
                            <h2 className="font-bold">Notifications</h2>
                            <div className="border border-zinc-200 rounded-lg p-3">
                                <div className="flex flex-col items-center justify-center h-56 gap-2">
                                    <div className="w-14 h-14 flex items-center justify-center bg-zinc-100 text-xl rounded-full">
                                        <IoIosNotifications />
                                    </div>
                                    <span className="text-zinc-500 text-sm">No Updates</span>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-1">
                            <h2 className="font-bold">Drafts</h2>
                            <div className="border border-zinc-200 rounded-lg p-3">
                                <div className="flex flex-col items-center justify-center h-56 gap-2">
                                    <div className="w-14 h-14 flex items-center justify-center bg-zinc-100 text-xl rounded-full">
                                        <IoIosDocument />
                                    </div>
                                    <span className="text-zinc-500 text-sm">Empty Drafts</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}