import React, { useContext } from "react"
import { AuthContext } from "../contexts/Auth";
import { IoIosLogOut } from "react-icons/io";
import { Windmill } from "react-activity";
import cn from "classnames";
import { Link } from "react-router-dom";

const Header: React.FC<{ title?: string, float?: boolean, small?: boolean }> = ({ title="", float=false, small=false }) => {
    const Auth = useContext(AuthContext);

    const handleLogout = async () => await Auth?.logout();

    return (    
        <header className={cn("top-0 z-50 bg-white border-b border-zinc-100 flex items-center justify-between", { 
            "sticky": !float, 
            "fixed w-full": float,
            "h-20 px-14": !small,
            "h-14 px-4": small
        })}>
            <div className="flex-1">
                <Link to="/" className="flex items-center font-bold"><span className="text-2xl pr-2">☗</span> Datahouse</Link>
            </div>

            {title !== "" && <div className="flex-1 font-bold text-sm text-center">{title} Market</div>}

            <div className="flex-1 flex justify-end items-center gap-6">
                <div className="text-sm">{Auth?.user?.email?.split("@")[0]}</div>
                <div className="h-10 border-r"></div>
                <button disabled={Auth?.loading} onClick={handleLogout} className="text-xl">
                    {Auth?.loading ? <Windmill /> : <IoIosLogOut />}
                </button>
            </div>
        </header>        
    )
}

export default Header;