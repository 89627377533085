import React, { useContext, useEffect, useRef, useState } from "react";
import { BsFacebook, BsTwitter, BsLinkedin, BsEnvelopeOpenFill, BsBroadcast } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import useMount from "../../hooks/useMount";
import { MetaData } from "../../lib/db";
import ContentEditable from "../ui/ContentEditable";

type MetaEditorProps = { 
    saved?: MetaData;
    onChange: (update: any) => void;
};
type MetaContentValue = { 
    meta: { 
        title: string, 
        description: string 
    },
    body: {
        h1: string,
        title: string
    },
    updateMetaValue: (target: string, value: string) => void;
    updateBodyValue: (target: string, value: string) => void;
};

const MetaEditorInterface = () => {
    const MetaEditorData = useContext(MetaContext);

    const handleMetaChange = (target: "title"|"description") => (event: React.ChangeEvent<HTMLElement>) => {
        MetaEditorData?.updateMetaValue(target, event.currentTarget.innerText);
    }

    const handleBodyChange = (target: "h1"|"title") => (event: React.ChangeEvent<HTMLElement>) => {
        MetaEditorData?.updateBodyValue(target, event.target.innerText);
    }

    const cursorTo = (el: Node, end: boolean, position?: number) => {
        const selection = window.getSelection()!;  
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(el);
        range.collapse(false);
        selection.addRange(range);  
        (el as HTMLElement).focus();
    }

    const RenderMetaResult = () => (
        <div className="space-y-1 select-none">
            <div className="text-sm text-zinc-800">https://consainsights.com › reports › ...</div>
            <div>
                <div className="text-lg font-medium text-blue-700 hover:underline cursor-pointer">{MetaEditorData?.meta.title || "Report Page Meta Title"}</div>
                <div className="text-sm text-zinc-800 max-w-xl">{MetaEditorData?.meta.description || "Report Page Meta Description"}</div>
            </div>
        </div>
    )

    const RenderBodyResult = () => (
        <div className="border rounded overflow-hidden select-none">
            <div className="h-10 border-b flex items-center px-4 justify-between">
                <div className="w-24 h-3 bg-zinc-200 rounded-sm"></div>
                <div className="flex items-center gap-4">
                    <div className="w-12 h-1 bg-zinc-200"></div>
                    <div className="w-12 h-1 bg-zinc-200"></div>
                    <div className="w-12 h-1 bg-zinc-200"></div>
                    <div className="w-12 h-1 bg-zinc-200"></div>
                </div>
                <IoSearch className="text-zinc-400" />
            </div>
            <div className="h-64 bg-zinc-50 flex flex-col items-center justify-center px-12 gap-1">
                <div className="font-bold text-lg text-center max-w-xl">{MetaEditorData?.body.h1 || "Report Heading"}</div>
                <div className="text-sm text-zinc-600 max-w-xl text-center">{MetaEditorData?.body.title || "Report Title"}</div>
                <div className="pt-2 flex items-center justify-center gap-4 text-xs">
                    <BsEnvelopeOpenFill />
                    <BsFacebook />
                    <BsTwitter />
                    <BsLinkedin />
                </div>
            </div>
        </div>        
    )

    return (
        <div id="Meta-Editor" className="space-y-8">
            <div className="space-y-3">
                <div className="space-y-1">
                    <div className="font-semibold text-sm text-red-500 flex items-center gap-1"><BsBroadcast className="animate-pulse" /> Live Preview</div>
                    <RenderMetaResult />
                </div>
                <div className="font-mono italic text-lg font-medium text-zinc-400">
                    {"<head>"}
                    <div className="pl-8 space-y-1">
                        {"<title>"}<ContentEditable tagname="span" value={MetaEditorData!.meta.title} onInput={handleMetaChange("title")} className="text-black border-2 rounded-lg px-2 mx-1 inline-block focus:border-blue-400 outline-none" />{"</title>"}<br/>
                        {"<meta name=\"description\" content=\""}<ContentEditable tagname="span" value={MetaEditorData!.meta.description} onInput={handleMetaChange("description")} className="text-black border-2 rounded-lg px-2 mx-1 inline-block focus:border-blue-400 outline-none" />{"\" />"}
                    </div>
                    {"</head>"}
                </div>                
            </div>
            <hr />
            <div className="space-y-3">
                <div className="space-y-1">
                    <div className="font-semibold text-sm text-red-500 flex items-center gap-1"><BsBroadcast className="animate-pulse" /> Live Preview</div>
                    <RenderBodyResult />
                </div>
                <div className="font-mono italic text-lg font-medium text-zinc-400">
                    {"<body>"}
                    <div className="pl-8 space-y-1">
                        {"<h1 id=\"report-name\">"}<ContentEditable tagname="span" value={MetaEditorData!.body.h1} onInput={handleBodyChange("h1")} className="text-black border-2 rounded-lg px-2 mx-1 inline-block focus:border-blue-400 outline-none" />{"</h1>"}<br />
                        {"<p id=\"report-title\">"}<ContentEditable tagname="span" value={MetaEditorData!.body.title} onInput={handleBodyChange("title")} className="text-black border-2 rounded-lg px-2 mx-1 inline-block focus:border-blue-400 outline-none" />{"</p>"}
                    </div>
                    {"</body>"}
                </div>
            </div>
        </div>
    )
}

const MetaContext = React.createContext<MetaContentValue|null>(null);

const MetaProvider: React.FC<MetaEditorProps> = ({ saved, onChange })  => {
    const { mounted } = useMount();
    const [meta, setMeta] = useState({ title: saved?.meta?.title ?? "", description: saved?.meta?.description ?? "" });
    const [body, setBody] = useState({ h1: saved?.body?.h1 ?? "", title: saved?.body?.title ?? "" });

    useEffect(() => {
        if (!mounted) return;
        onChange({meta, body});
    }, [mounted, meta, body])

    const updateMetaValue = (target: string, value: string) => {
        setMeta(prev => ({...prev, [target]:  value}));
    }

    const updateBodyValue = (target: string, value: string) => {
        setBody(prev => ({...prev, [target]:  value}));
    }    

    const value = {
        meta,
        body,
        updateMetaValue,
        updateBodyValue
    };

    return (
        <MetaContext.Provider value={value}>
            <MetaEditorInterface />
        </MetaContext.Provider>
    )
}

const MetaEditor: React.FC<MetaEditorProps> = (props) => {
    return <MetaProvider {...props} />
}

export default MetaEditor;