import { useState } from "react";
import { getReportById, Report } from "../lib/db";

export default function useReport(withFeaturedContent=false) {
    const [report, setReport] = useState<Report|null>(null);
    const [loading, setLoading] = useState(false);

    const fetchReport = async (id: string) => {
        setLoading(true);
        const response = await getReportById(id, withFeaturedContent);
        setReport(response);
        setLoading(false);
    }

    return { report, loading, fetchReport };
}