import cn from "classnames";
import React from "react"

type RoundButtonProps = { name: string, children: React.ReactNode, small?: boolean };
const RoundButton: React.FC<RoundButtonProps & React.ButtonHTMLAttributes<any>> = ({ name, children, className, small=false, ...props }) => (
    <div className="inline-flex flex-col items-center gap-1">
        <button {...props} className={cn("rounded-full border border-zinc-400 flex items-center justify-center hover:bg-blue-500 hover:border-transparent hover:text-white  transition-transform duration-300 active:scale-90", className, {
            "w-14 h-14 text-4xl": !small,
            "w-8 h-8 text-base": small
        })}>
            {children}
        </button>
        <span className="text-xs text-zinc-500">{name}</span>
    </div>
)

export default RoundButton;