import React, { useState } from "react";
import { getMarketById, Market } from "../lib/db";

const useMarket = (withMeta?: boolean) => {
    const [market, setMarket] = useState<Market|null>(null);
    const [loading, setLoading] = useState(false);

    const fetchMarket = async (id: string) => {
        setLoading(true);
        const response = await getMarketById(id, !!withMeta);
        setMarket(response);
        setLoading(false);
    }

    return { market, loading, fetchMarket };
}

export default useMarket;