import { createClient } from "@supabase/supabase-js";

export const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL ?? "", 
    process.env.REACT_APP_SUPABASE_API ?? ""
);

export type MetaData = { 
    meta?: { 
        title?: string, 
        description?: string 
    },
    body?: {
        h1?: string,
        title?: string
    }
};

export type Market = { id: string, market: string, updated: string, meta?: MetaData };

export type DraftData = {
    items: FeaturedContent[];
    objects: Array<TableComponent|GraphComponent>;
};

export type ReportContent = {
    segments: Array<{by: string, sections: SegmentSections}>,
    companies: CompanySections
};

type SegmentSections = { [key: string]: SegmentSections|object };

type CompanySections = { [key: string]: CompanySections|object };

export enum FeaturedComponent {
    Text = "text",
    Graph = "graph",
    Table = "table"
};

export enum TextType {
    H1 = "h2",
    H2 = "h3",
    H3 = "h4",
    H4 = "h5",
    P = "p",
    DIV = "div"
}

export type TextComponent = {
    component: FeaturedComponent.Text,
    value: string,
    name: TextType,
    isPoint: boolean
}

export enum GraphType {
    Bar = "bar",
    Pie = "pie",
    Stack = "stack"
};

export type GraphComponent = {
    component: FeaturedComponent.Graph,
    type: GraphType,
    title: string,
    name: string,
    otherVariant: boolean,
    variantRowIndex: number,
    header: string[],
    rows: Array<string[]>,
    baseYearIndex: number,
    compareYearIndex: number
}

export type TableComponent = {
    component: FeaturedComponent.Table,
    title: string,
    name: string,
    header: string[],
    rows: Array<string[]>,
    baseYearIndex: number,
    compareYearIndex: number
}

export type FeaturedContent = TextComponent | GraphComponent | TableComponent;

export type Report = {
    id: string,
    market: string,
    contents: ReportContent,
    featuredContent?: Array<FeaturedContent>
}

export async function getSearchResults(query: string): Promise<Market[]> {
    const { data }: { data: unknown } = await supabase.from("reports")
        .select("id,market,updated")
        .textSearch("market", query, {
            type: "websearch",
            config: "english"
        })
        .limit(10);
    
    return (data ?? []) as Market[];
}

export async function getMarketById(id: string, withMeta=false): Promise<Market|null> {
    const { data }: { data: any } = await supabase.from("reports")
        .select("id,market,updated" + (withMeta ? ",meta(data)" : ""))
        .eq("id", id)
        .single();

    if (data && withMeta && data.meta.length > 0) {
        return {...(data as any), meta: (data as any).meta[0].data} as Market;
    }
    
    return (data as unknown) as Market|null;
}

export async function getReportById(id: string, withFeaturedContent=false): Promise<Report|null> {
    const { data }: { data: unknown } = await supabase.from("reports")
        .select("id,market,contents" + (withFeaturedContent ? ",featuredContent" : ""))
        .eq("id", id)
        .single();
    
    return data as Report ?? null;
}

export async function setReportMeta(id: string, data: MetaData): Promise<boolean> {
    const { error } = await supabase.from("meta")
        .upsert({ id, data, updated: ((new Date()).toISOString()).toLocaleString() }, { onConflict: "id" })
        .single();
    await purgeCache(`https://consainsights.com/reports/${id}-market`);
    if (error) return false;
    return true;
}

export async function deleteReportMeta(id: string): Promise<boolean> {
    const { error } = await supabase.from("meta")
        .delete()
        .match({ id })
        .single();
    await purgeCache(`https://consainsights.com/reports/${id}-market`);
    if (error) return false;
    return true;
}

export async function setReportContents(id: string, contents: ReportContent): Promise<boolean> {
    const { error } = await supabase.from("reports")
        .update({ contents })
        .match({ id })
        .single();
    await purgeCache(`https://consainsights.com/reports/${id}-market`);
    if (error) return false;
    return true;
}

export async function setReportFeaturedContent(id: string, featuredContent: FeaturedContent[]): Promise<boolean> {
    const { error } = await supabase.from("reports")
        .update({ featuredContent })
        .match({ id })
        .single();
    await purgeCache(`https://consainsights.com/reports/${id}-market`);
    if (error) return false;
    return true;
}

export async function setReportDraft(id: string, content: DraftData): Promise<boolean> {
    const { error } = await supabase.from("editor_drafts")
        .upsert({ id, content, updated: ((new Date()).toISOString()).toLocaleString() }, { onConflict: "id" });
    if (error) return false;
    return true;
}

export async function getReportDraft(id: string): Promise<DraftData|null> {
    const { data, error } = await supabase.from("editor_drafts")
        .select("content")
        .eq("id", id)
        .single();
    if (error) return null;
    if (data === null) return null;
    return (data as unknown as { content: DraftData }).content;
}

export async function purgeCache(url: string) {
    const { error } = await supabase.functions.invoke("purge-cache", {
        body: JSON.stringify({ url })
    });
    if (error) console.error(error);
}