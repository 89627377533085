export type CursorPosition = { position: number, endOffset: number };

export const cursorPosition = (node: Node): CursorPosition => {
    let position = 0, endOffset = 0;
    const select = window.getSelection()!;
    
    if(select.rangeCount !== 0) {
        const range = window.getSelection()!.getRangeAt(0);
        const preCursorRange = range.cloneRange();
        
        preCursorRange.selectNodeContents(node);
        preCursorRange.setEnd(range.endContainer, range.endOffset);

        position = preCursorRange.toString().length;
        endOffset = (select.focusNode! as any).length;
    }

    return { position, endOffset };
}

export const cursorTo = (el: Node, position?: number) => {
    const selection = window.getSelection()!;
    const range = document.createRange();

    selection.removeAllRanges();

    if (typeof position !== "undefined") {
        range.setEnd(el, position);
        range.collapse(true);
    } else {
        range.selectNodeContents(el);
        range.collapse(false);
    }

    selection.addRange(range);

    (el as HTMLElement).focus();
}