import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../components/contexts/Auth";
import useMount from "../../hooks/useMount";
import PasswordReset from "../Misc/PasswordReset";
import AuthScreen from "./AuthScreen";
import Home from "./HomeScreen";

export default function Entry() {
    const { mounted } = useMount();
    const location = useLocation()
    const Auth = useContext(AuthContext);
    const { hash } = useLocation();
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (!mounted) return;
        if (!reset) setReset(hash.includes("access_token"));
    }, [mounted, hash])

    if (reset) {
        return <PasswordReset />;
    }    

    if (!Auth?.authenticated) {
        return <AuthScreen />;
    }

    return <Home />;
}