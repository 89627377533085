import React, { useEffect, useState } from "react";
import { Windmill } from "react-activity";
import { BiRefresh } from "react-icons/bi";
import { IoCaretBack } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/core/Header";
import ContentEditor from "../../components/editors/contents";
import useMount from "../../hooks/useMount";
import useReport from "../../hooks/useReport";
import { ReportContent, setReportContents } from "../../lib/db";

const ContentScreen = () => {
    const { mounted } = useMount();
    const { id } = useParams();
    const { report, loading, fetchReport } = useReport(false);
    const [contents, setContents] = useState<ReportContent|null>(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (mounted && id) {
            fetchReport(id);
        }
    }, [mounted, id])

    const handleChange = (update: any) => {
        setContents(update);
    }

    const saveChanges = async () => {
        if (!id || contents === null) return;
        setSaving(true);
        const response = await setReportContents(id, contents);
        if (!response) alert("Failed to save!");
        setSaving(false);
    }

    return (
        <div>
            <Header small />
            <div className="bg-white sticky top-14 px-4 border-b border-zinc-100 h-12 z-50 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <Link to={`/market/${id}`} className="font-bold flex items-center gap-1"><IoCaretBack /> {report?.market} Market</Link>
                </div>

                <button disabled={saving} onClick={saveChanges} className="bg-black px-4 py-1 rounded-md font-medium text-white text-sm disabled:bg-zinc-400">{saving ? <BiRefresh className="animate-spin" /> : "Save"}</button>
            </div>

            <div className="mx-auto my-10 max-w-screen-md">
                {loading && <div style={{ height: "calc(100vh - 200px)" }} className="flex items-center justify-center"><Windmill /></div>}
                {!loading && <ContentEditor saved={report?.contents} onChange={handleChange} />}
            </div>
        </div>
    );
}

export default ContentScreen;