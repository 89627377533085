import { useState } from "react";
import { getSearchResults, Market } from "../lib/db";

export default function useSearch() {
    const [results, setResults] = useState<Market[]>([]);
    const [loading, setLoading] = useState(false);

    const search = async (query: string) => {
        setLoading(true);
        const response = await getSearchResults(query);
        setResults(response);
        setLoading(false);
    }

    return { results, loading, search };
}