import React, { useRef } from "react";

type ContentEditableProps = { tagname: keyof React.ReactHTML, value: string } & React.HTMLAttributes<any>;
const ContentEditable = React.forwardRef<unknown, ContentEditableProps>(({ tagname, value, ...props }, ref) => {
    const valueRef = useRef(value);

    return React.createElement(tagname, { 
        ...props,
        ref,
        dangerouslySetInnerHTML: {
            __html: valueRef.current
        },
        contentEditable: true,
        suppressContentEditableWarning: true        
    });
})

export default ContentEditable;