import { Market } from "../../lib/db";
import { IoIosCode, IoIosList, IoIosResize } from "react-icons/io";
import RoundButton from "../ui/RoundButton";
import moment from "moment";
import { Link } from "react-router-dom";

const ResultItem: React.FC<Market> = ({ id, market, updated }) => (
    <div className="flex items-center p-3">
        <div className="space-y-1 flex-1">
            <div>
                <Link to={`/market/${id}`} className="font-bold hover:text-blue-500">{market} Market</Link>
            </div>
            <div className="flex items-center gap-4">
                <div className="text-xs text-zinc-500">Last Modified: {moment.duration(updated).humanize()}</div>
            </div>
        </div>        

        <div className="flex items-center gap-6">
            <Link to={`/market/${id}/meta`}>
                <RoundButton small className="bg-zinc-100 border-none" name="Meta"><IoIosCode /></RoundButton>
            </Link>
            <Link to={`/market/${id}/contents`}>
                <RoundButton small className="bg-zinc-100 border-none" name="Contents"><IoIosList /></RoundButton>
            </Link>
            <Link to={`/market/${id}/report`}>
                <RoundButton small className="bg-zinc-100 border-none" name="Report"><IoIosResize /></RoundButton>
            </Link>
        </div>
    </div>
)

export default ResultItem;